define("discourse/plugins/chat/discourse/components/chat-channel-unread-indicator", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showUnreadIndicator}}
    <div
      class={{concat-class
        "chat-channel-unread-indicator"
        (if this.isUrgent "-urgent")
      }}
    >
      <div class="chat-channel-unread-indicator__number">{{#if
          this.showUnreadCount
        }}{{this.unreadCount}}{{else}}&nbsp;{{/if}}</div>
    </div>
  {{/if}}
  */
  {
    "id": "YuxjflSR",
    "block": "[[[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"  \"],[10,0],[15,0,[28,[37,1],[\"chat-channel-unread-indicator\",[52,[30,0,[\"isUrgent\"]],\"-urgent\"]],null]],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-channel-unread-indicator__number\"],[12],[41,[30,0,[\"showUnreadCount\"]],[[[1,[30,0,[\"unreadCount\"]]]],[]],[[[1,\" \"]],[]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"concat-class\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-unread-indicator.hbs",
    "isStrictMode": false
  });
  let ChatChannelUnreadIndicator = _exports.default = (_class = class ChatChannelUnreadIndicator extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
    }
    get showUnreadIndicator() {
      return this.args.channel.tracking.unreadCount > 0 ||
      // We want to do this so we don't show a blue dot if the user is inside
      // the channel and a new unread thread comes in.
      this.chat.activeChannel?.id !== this.args.channel.id && this.args.channel.unreadThreadsCountSinceLastViewed > 0;
    }
    get unreadCount() {
      return this.args.channel.tracking.unreadCount;
    }
    get isUrgent() {
      return this.args.channel.isDirectMessageChannel || this.args.channel.tracking.mentionCount > 0;
    }
    get showUnreadCount() {
      return this.args.channel.isDirectMessageChannel || this.isUrgent;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatChannelUnreadIndicator);
});