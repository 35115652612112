define("discourse/plugins/chat/discourse/controllers/preferences-chat", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/services/chat-audio-manager"], function (_exports, _controller, _object, _service, _ajaxError, _environment, _decorators, _discourseI18n, _chatAudioManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const CHAT_ATTRS = ["chat_enabled", "only_chat_push_notifications", "ignore_channel_wide_mention", "chat_sound", "chat_email_frequency", "chat_header_indicator_preference", "chat_separate_sidebar_mode"];
  const EMAIL_FREQUENCY_OPTIONS = [{
    name: _discourseI18n.default.t("chat.email_frequency.never"),
    value: "never"
  }, {
    name: _discourseI18n.default.t("chat.email_frequency.when_away"),
    value: "when_away"
  }];
  const HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = "never";
  const HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = "dm_and_mentions";
  const HEADER_INDICATOR_PREFERENCE_ALL_NEW = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = "all_new";
  const HEADER_INDICATOR_OPTIONS = [{
    name: _discourseI18n.default.t("chat.header_indicator_preference.all_new"),
    value: HEADER_INDICATOR_PREFERENCE_ALL_NEW
  }, {
    name: _discourseI18n.default.t("chat.header_indicator_preference.dm_and_mentions"),
    value: HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS
  }, {
    name: _discourseI18n.default.t("chat.header_indicator_preference.never"),
    value: HEADER_INDICATOR_PREFERENCE_NEVER
  }];
  const CHAT_SEPARATE_SIDEBAR_MODE_OPTIONS = [{
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.always"),
    value: "always"
  }, {
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.fullscreen"),
    value: "fullscreen"
  }, {
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.never"),
    value: "never"
  }];
  let PreferencesChatController = _exports.default = (_class = class PreferencesChatController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatAudioManager", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _defineProperty(this, "subpageTitle", _discourseI18n.default.t("chat.admin.title"));
      _defineProperty(this, "emailFrequencyOptions", EMAIL_FREQUENCY_OPTIONS);
      _defineProperty(this, "headerIndicatorOptions", HEADER_INDICATOR_OPTIONS);
      _defineProperty(this, "chatSeparateSidebarModeOptions", CHAT_SEPARATE_SIDEBAR_MODE_OPTIONS);
    }
    get chatSeparateSidebarMode() {
      const mode = this.model.get("user_option.chat_separate_sidebar_mode");
      if (mode === "default") {
        return this.siteSettings.chat_separate_sidebar_mode;
      } else {
        return mode;
      }
    }
    chatSounds() {
      return Object.keys(_chatAudioManager.CHAT_SOUNDS).map(value => {
        return {
          name: _discourseI18n.default.t(`chat.sounds.${value}`),
          value
        };
      });
    }
    onChangeChatSound(sound) {
      if (sound) {
        this.chatAudioManager.playImmediately(sound);
      }
      this.model.set("user_option.chat_sound", sound);
    }
    save() {
      this.set("saved", false);
      return this.model.save(CHAT_ATTRS).then(() => {
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatAudioManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chatSounds", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "chatSounds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeChatSound", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeChatSound"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
});